.ant-modal {
  .ant-modal-header {
    border-radius: 5px 5px 0 0;
  }

  .ant-modal-content {
    border-radius: 5px;

    .ant-btn {
      min-width: initial;
    }
  }

  .ant-modal-footer {
    text-align: center;
    padding-top: 0px;
    padding-bottom: 20px;
    border-top: 0px !important;
  }
}

.ant-modal-confirm {
  width: 500px !important;
}

.ant-modal-mask,
.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.6);
}
