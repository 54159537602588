.menu-actions {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-item > a {
    color: #626262;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    &:hover {
      background-color: #f1f1f1;
    }

    svg {
      font-size: 0.7em;
      margin-right: 10px;
    }
  }
}
