.catastro-historial {
  .ant-modal-body {
    padding: 0px;
    padding-bottom: 20px;

    .table {
      tr {
        th,
        td {
          &:nth-child(2) {
            text-align: center;
          }
          &:nth-child(3) {
            text-align: center;
          }
          &:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }
}
