.form {
  padding: 30px;
  margin-top: 80px;
  margin-bottom: 30px;

  .form-icon {
    font-size: 5em;
    background: #f1eef6;
    color: #04bebe;
    text-align: center;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-top: -90px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);

    img {
      max-height: 80px;
      margin-top: -10px;
    }
  }

  .form-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
    background-color: #f1eef6;
    color: #04bebe;
    padding: 15px 30px;
    margin: 15px -30px 15px -30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      margin-right: 10px;
    }
  }
}

.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  > * {
    margin: 7px;
  }
}

.ant-modal-body {
  .form {
    padding: 0px;
    margin: 0px;
  }
}

.form-subsection {
  width: 100%;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  > .form-subsection-header {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    > .icon {
      font-size: 2em;
      background: #f1eef6;
      color: #04bebe;
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 100px;
      text-align: center;
      padding-top: 7px;
      margin-right: 15px;

      > img {
        max-height: 35px;
        margin-top: -13px;
      }
    }

    > .content {
      > .title {
        font-weight: 500;
        font-size: 15px;
      }

      > .subtitle {
        font-size: 14px;
        color: #888;
      }
    }

    > .collapse {
      margin-left: auto;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;

      &:hover {
        background-color: #fafafa;
      }
    }
  }

  > .react-slidedown {
    > .form-subsection-content {
      border-top: 1px solid #efefef;

      .actions {
        padding: 20px;
        text-align: center;

        > button {
          margin: 0 auto;
        }
      }
    }
  }

  .td-icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      max-width: 30px;
      margin-right: 20px;
    }
  }
}
