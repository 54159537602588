.ant-btn {
  line-height: 1;
  padding: 0.9rem 2rem;
  border-radius: 0.4285rem;
  height: auto;
  min-width: 150px;
}

.ant-btn-primary {
  color: #fff;
  border: none !important;
  background-color: #04bebe !important;
  transition: box-shadow 0.35s ease;

  &:hover {
    box-shadow: 0 0 10px 1px rgba(4, 190, 190, 0.7) !important;
  }
}

.circle-inline {
  margin-top: 22px;
  height: 40px;
  min-width: 40px;
  width: 40px;
  border-radius: 100px;
  padding: 0px;
}