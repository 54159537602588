.perfil {
  .form {
    .modules {
      border-radius: 5px;

      > .module {
        border: 1px solid #ddd;
        border-bottom: none;

        &:first-of-type {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        &:last-of-type {
          border-bottom: 1px solid #ddd;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        > .title {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
        }

        .actions {
          padding: 20px;
          padding-top: 0px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .action {
            font-size: 0.8rem;
            min-width: 140px;
            text-align: center;
            margin-bottom: 20px;

            p {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}
