.expediente {
  .section-estado {
    > .ant-row {
      margin-bottom: 0px;
    }

    small {
      cursor: pointer;

      &:hover {
        color: #999;
      }
    }
  }

  .items {
    .table {
      tr {
        :nth-child(3) {
          text-align: center;
        }

        :nth-child(4) {
          text-align: center;
        }

        :nth-child(5) {
          text-align: center;
        }

        :nth-child(6) {
          text-align: center;
        }

        :nth-child(7) {
          text-align: center;
        }
      }
    }
  }

  .entradas {
    .table {
      tr {
        :nth-child(2) {
          width: 80px;
        }

        :nth-child(4) {
          text-align: left;
        }

        :nth-child(5) {
          text-align: left;
        }

        :nth-child(6) {
          text-align: center;
        }
      }
    }
  }
}
