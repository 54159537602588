.tablero-card {
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  > .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  > .subtitle {
    font-size: 13px;
    color: #999;
  }
}
