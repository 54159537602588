.ant-notification-notice {
  border-radius: 10px;

  .ant-notification-notice-message {
    font-weight: 500;
  }
}

.swal-title {
  font-size: 20px;
}

.swal-text {
  font-size: 14px;
  text-align: center;
}

.swal-footer {
  text-align: center;

  .swal-button {
    background-color: #7367f0;
    color: #fff;
    font-weight: 500;

    &:not([disabled]):hover {
      background-color: #7367f0;
      box-shadow: 0 8px 25px -8px #7367f0;
    }
  }

  .swal-button--cancel {
    color: #82868b;
    background-color: transparent;

    &:hover {
      color: rgba(0, 0, 0, 0.85) !important;
      background-color: rgba(0, 0, 0, 0.018) !important;
      box-shadow: none !important;
    }
  }
}
