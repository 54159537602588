.filters {
  .ant-drawer-body {
    padding: 0px;

    #form-filtros {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;

      .filters-content {
        height: 100%;
        padding: 24px;
      }

      .filters-actions {
        padding: 24px;

        button {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
}
