.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;
}
.ant-form-item-label > label,
.ant-form label {
  color: #464646;
  font-size: 0.8rem;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

a {
  color: #626262 !important;
}
