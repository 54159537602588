.card-table {
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .table-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 10px;
  }

  > .empty {
    width: 100%;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    padding-bottom: 80px;

    > img {
      width: 80px;
      display: block;
      margin: 0 auto 10px auto;
    }
  }

  > .loading {
    width: 100%;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    padding-bottom: 80px;

    > svg {
      margin: 0 auto 10px auto;
    }
  }

  > .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;

    > .right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .count {
      font-size: 0.8rem;
    }

    .ant-pagination {
      margin-left: 20px;
    }
  }
}

.table {
  min-width: 100%;

  &.table-small {
    > thead {
      > tr {
        > th {
          font-size: 14px;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          font-size: 13px;
        }
      }
    }
  }

  > thead {
    > tr {
      > th {
        padding: 12px;
        font-size: 16px;
        font-weight: 500;
        text-align: left;

        &:first-of-type {
          padding-left: 30px;
        }

        &.col-checkbox {
          padding-right: 0px !important;
        }
      }
    }
  }

  > tbody {
    > tr {
      &:first-of-type {
        border-top: 2px solid #f1f1f1;
      }

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #ece7f2;
      }

      &.warning {
        background: #fff3cd !important;

        > td {
          color: #997404 !important;

          .icon {
            color: #ff9f43 !important;
          }
        }
      }

      > td {
        padding: 12px;

        &:first-of-type {
          padding-left: 30px;
        }

        &.col-checkbox {
          padding-right: 0px !important;
        }

        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        .icon {
          font-size: 20px;
          margin: 0 10px;
        }
      }
    }
  }

  .ant-checkbox {
    margin-right: 10px;
  }

  .table-empty {
    background-color: #fafafa;
    text-align: center;
    font-size: 13px;
  }

  .table-footer {
    padding-right: 30px;
    font-size: 22px;
    font-weight: 500;
  }
}

.table-actions {
  min-width: 60px;
  width: 60px;
  padding-left: 12px !important;

  > div {
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
  }
}

.table-actions-menu {
  > .ant-dropdown-menu-item {
    min-width: 130px;

    svg {
      font-size: 13px;
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .table {
    > .footer {
      display: block;
      text-align: center;
      padding: 0px 20px;

      > .count {
        margin-bottom: 20px;
      }
    }
  }
}
