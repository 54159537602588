.references {
  padding: 10px 20px;

  .estado {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 500;

    > .color {
      height: 15px;
      width: 15px;
      border-radius: 3px;
      margin-right: 5px;

      &.precarga {
        background-color: #ff3838;
      }

      &.pendiente {
        background-color: #ff9f1a;
      }

      &.encurso {
        background-color: #fff200;
      }

      &.terminado {
        background-color: #3ae374;
      }

      &.rechazado {
        background-color: #67e6dc;
      }

      &.supervisado {
        background-color: #7158e2;
      }

      &.solucionado {
        background-color: #c56cf0;
      }
    }
  }
}

.infowindow {
  font-size: 11px;
  min-width: 170px;

  .title {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
    color: #04bebe;
  }

  .row {
    .value {
      margin-left: 10px;
    }
  }
}
