.form-update {
  width: 100%;
  max-width: 400px;
  text-align: left;

  .title {
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 40px 0;
  }

  .form-actions {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    > * {
      margin: 0 10px;
    }
  }
}
