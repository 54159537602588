.chart {
  margin-bottom: 30px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 40px 0px 40px;
    margin: 0;

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .update {
      font-size: 12px;
      color: #999;
    }

    .year {
      .ant-form-item {
        margin-bottom: 0 !important;

        input {
          text-align: right;
          font-weight: 600;
          padding-right: 10px;
        }
      }
    }
  }

  > .empty {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    padding-bottom: 80px;

    > img {
      width: 80px;
      display: block;
      margin: 0 auto 10px auto;
    }
  }

  .canvas {
    padding-bottom: 40px;
  }

  &.card {
    .header {
      padding: 20px 40px;
      border-bottom: 1px solid #ebe9f1;
    }
  }
}
