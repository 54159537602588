.ant-radio-button-wrapper:hover {
  color: #722ed1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #722ed1;
  border-color: #722ed1 !important;
  background-color: #f1eef6;

  &:hover {
    color: #722ed1;
    border-color: #722ed1;
  }

  &:first-child {
    border-color: #722ed1;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #722ed1;
}
