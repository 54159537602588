.inventario-movimientos {
  .ant-modal-body {
    padding: 0 !important;

    .table {
      tr {
        :nth-child(5) {
          text-align: center;
        }

        :nth-child(6) {
          text-align: center;
        }

        :nth-child(7) {
          text-align: center;
        }
      }
    }

    .actions {
      padding: 20px;
      text-align: center;
    }
  }
}
