.ant-picker {
  width: 100%;
  border-radius: 5px;
  min-height: 38px;
}
.ant-picker:hover {
  border-color: #d9d9d9;
}
.ant-picker-focused {
  border-color: #7367f0 !important;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.ant-picker-date-panel .ant-picker-month-btn,
.ant-picker-date-panel .ant-picker-content thead tr th,
.ant-picker-cell .ant-picker-cell-inner {
  text-transform: capitalize !important;
}

.ant-picker-today-btn {
  color: #7367f0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #7367f0;
}
