.ant-tag {
  border-radius: 5px;
  font-weight: 500;
  margin-right: 0;
  cursor: pointer;

  &.ant-tag-purple {
    background: #f1eef6;
    border-color: #d3adf7;
  }
}
