.login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .card {
    height: 700px;
    width: 100%;
    max-width: 1100px;
    border-radius: 10px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    margin: 0 25px;

    >.ant-row {
      height: 100%;
    }

    .login-sidebar {
      height: 100%;
      padding: 0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background-image: url("../../assets/images/login-sidebar.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      text-align: center;

      .overlay {
        height: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: linear-gradient(45deg,
            rgba(4, 190, 190, 0.6) 40%,
            rgba(0, 0, 0, 0.52) 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 150px 30px 20px 30px;

        .title {
          color: #fff;
          font-size: 2rem;
          font-weight: 600;
        }

        .copyright {
          color: #fff;
          font-size: 0.75rem;

          a {
            color: #fff !important;
          }
        }
      }
    }

    .login-form {
      height: 100%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      text-align: center;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      .logo {
        max-height: 80px;
        margin-bottom: 20px;
      }

      .animated {
        width: 100%;
        max-width: 400px;
      }
    }
  }
}