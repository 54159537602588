.master {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  color: #626262;

  > .master-scroll {
    > div {
      > .content {
        width: 100%;
        padding: 18px 31px 31px 31px;

        > .switch-wrapper {
          position: relative;
          width: 100%;
          height: calc(100vh - 120px);

          > div {
            position: absolute;
            width: 100%;
            height: calc(100vh - 120px);

            > div {
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .master {
    > .master-scroll {
      > div {
        > .content {
          padding: 15px;
        }
      }
    }
  }
}
