#form-entradas {
  .tipo {
    text-align: center;
    padding: 10px 0 20px 0;
  }

  .product-tag {
    position: absolute;
    right: 30px;
    top: 32px;
  }
}
