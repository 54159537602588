.modal-catastro-import {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      cursor: pointer !important;
      transition: all 0.3s ease-in-out;
      border: 1px solid #eee;
      gap: 20px;

      &:hover {
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.25);
      }

      > .image {
        > img {
          height: 40px;
          width: 40px;
        }
      }

      .data {
        > .title {
          font-weight: 500;
        }

        > .description {
          font-size: 13px;
          color: #888;
        }
      }
    }
  }
}
