.sidebar {
  background-color: #fff;
  height: 100vh;
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: max-width 0.35s ease, min-width 0.35s ease;

  >.sidebar-header {
    padding: 20px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    transition: margin 0.35s ease;

    >.sidebar-logo {
      height: 35px;
      margin-top: 6px;
    }

    >.sidebar-title {
      text-align: left;
      padding: 0 10px;
      margin: 5px 0 0 0;
      height: 44px;
      font-size: 1.2rem;
      font-weight: 600;
      white-space: nowrap;

      >.small {
        font-size: 0.6em;
        margin-bottom: 0;
        display: block;
      }

      >.name {
        margin-top: -5px;
        display: block;
      }
    }

    >.sidebar-collapse {
      display: none;
    }
  }

  .sidebar-scroll {
    transition: width 0.35s ease;

    .sidebar-list {
      list-style-type: none;
      text-align: left;
      padding: 19px 15px 0 15px;
      margin-bottom: 0px;

      >.nav-header {
        font-size: 0.8rem;
        text-transform: uppercase;
        margin: 30px 0 5px 15px;
        color: #999;
      }

      >.nav-item {
        a {
          display: flex;
          color: #565656;
          padding: 10px 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          font-size: 0.88rem;
          border-radius: 5px;
          margin-bottom: 10px;
          height: 43px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          transition: padding 0.35s ease;

          &:hover {
            padding-left: 30px;
          }

          svg {
            font-size: 1.4em !important;
            margin-right: 0.7em;
          }

          &.active {
            background: linear-gradient(118deg, #04bebe, #04bebe);
            box-shadow: 0 0 10px 1px rgba(4, 190, 190, 0.7) !important;
            color: #fff !important;
          }
        }
      }
    }
  }

  &.collapsed {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    text-align: center !important;

    .sidebar-header {
      margin-left: 5px;

      .sidebar-title {
        display: none;
      }
    }

    .sidebar-list {
      padding: 28px 15px 0 15px;

      .nav-header {
        display: none;
      }

      .nav-item {
        a {
          padding: 10px 15px;

          &:hover {
            padding-left: 15px;
          }

          svg {
            margin: 0 auto;
          }

          div {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .sidebar-overlay {
    position: absolute;

    &.visible {
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
    }

    .sidebar {
      left: 0;
      min-width: 260px !important;
      max-width: 260px !important;
      position: absolute;
      z-index: 2;
      height: 100vh;
      transition: left 0.35s ease;

      &.collapsed {
        left: -310px;
        width: 0px;
      }

      >.sidebar-header {
        >.sidebar-collapse {
          display: inline;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }
}