.catastro {
  .section-estado {
    > .ant-row {
      margin-bottom: 0px;
    }

    small {
      cursor: pointer;

      &:hover {
        color: #999;
      }
    }
  }

  .comentarios {
    > .comentario-item {
      display: flex;
      flex-direction: row;

      &:nth-child(even) {
        background-color: #f5f5f5;
      }

      > .comentario-actions {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin: 12px 10px 0px 10px;
        text-align: center;
      }

      > .comentario-body {
        padding: 10px;
        padding-left: 0;

        > .comentario-title {
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          > .comentario-user {
            font-weight: 500;
          }

          > .comentario-date {
            font-size: 12px;
            margin-left: 10px;
            color: #999;
          }
        }

        > .comentario-content {
          padding: 5px 0 10px 0;
        }
      }
    }
  }
}
