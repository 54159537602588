.ant-tabs-nav {
  margin-bottom: 0px !important;
  min-width: 100%;
  padding: 5px 40px 0px 40px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #04bebe;
  font-weight: 600;
}
.ant-tabs-ink-bar {
  background: #04bebe;
}
