.inicio {
  .section-header {
    font-weight: 500;
    font-size: 1.3rem;
    color: #636363;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 10px 20px;
  }

  .card {
    text-align: center;
    color: #000;
    padding: 20px;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.25);
    }

    .card-icon {
      font-size: 2em;
      border-radius: 100px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    .card-count {
      font-weight: 600;
      font-size: 1.74rem;
      margin-top: 10px;
    }

    .card-title {
      color: #626262;
    }

    &.primary {
      > .card-icon {
        background: #f1eef6;
        color: #04bebe;
      }
    }

    &.success {
      > .card-icon {
        background: #f0f6ec;
        color: #94bb76;
      }
    }

    &.info {
      > .card-icon {
        background: #edfffd;
        color: #009f8a;
      }
    }
  }
}
